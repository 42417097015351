export const placeLocation = "tdo";
export let serverURI = "https://tdo-4cad66b1b452.herokuapp.com";

export const colors = {
  main: "white", //white
  secondary: "#777758", //vert foncé
  tertiary: "#62714e", // vert clair
  ecriture: "white", //black
  background: "#f4ba9a",
  background2: "#ff8080",
  gold: "#7B7338",
};
// pour le style2 et non pas le style ardoise
export const categoriesStyle2 = [];

// pour les separations entre les articles
export const allowedCategories = [];

export const phoneNumber = "+33495283672";

export const facebook =
  "https://www.facebook.com/people/TDO-le-temps-des-oliviers/100063509410835/";
export const instagram =
  "https://www.instagram.com/le_temps_des_oliviers/?hl=fr";
export const url = "https://la-carte.le-tdo.com/";
export const mail = "letempsdesoliviers@hotmail.fr";

export const GOOGLE_API_KEY = "";
